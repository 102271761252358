import React, { FC } from "react";
import { FlexRow } from "@kit/kit-aiss";
import { observer } from "mobx-react-lite";
import { ICreateCallModalProps } from "./types";
import Form from "../Form";
import Button from "../../../../../components/Button";
import { CreateCallModalStore } from "./store";
import Modal from "../../../../../components/Modal";
import { STORAGE_KEY_CREATE_CALL } from "../../../../../constants/storage";
import "./styles.scss";
import { CallsStore } from "../../../../Calls/store";
import GlobalStatusStore from "../../../../../stores/GlobalStatusStore";
import sipControllerStore from "../../../../../stores/GlobalStatusStore/SipController";

const callStore = new CallsStore();
const store = new CreateCallModalStore();

const CreateCallModal: FC<ICreateCallModalProps> = ({
  isOpen,
  onClose,
  onCloseAndAndGoAppeal,
}) => {
  const handleCreate = async () => {
    if (!store.form.validate()) return;

    const isSuccess = await store.onSave();
    if (!isSuccess) {
      alert("Не удалось сохранить звонок.");
      return;
    }

    GlobalStatusStore.pauseCallsTemporally();

    onClose(true);
    callStore.getRingTableData();
    sipControllerStore.resetReinviteTel();
    sipControllerStore.setIsRedirected(false);
    GlobalStatusStore.setState("isCreateCall", false);
    GlobalStatusStore.setState("isOutGoing", false);
  };

  const handleCreateAndGoAppeal = async () => {
    store.form.form.validate();

    store.form.attribute?.attributes.forEach((attr) => {
      attr.form.validate();
    });

    const isEveryAttrValid = store.form.attribute?.attributes.every(
      ({ form }) => form.isValid
    );

    if (!store.form.form.isValid || !isEveryAttrValid) return;

    const isSuccess = await store.onSave();
    if (!isSuccess) {
      alert("Не удалось сохранить звонок.");
      return;
    }

    onClose();
    onCloseAndAndGoAppeal();
    GlobalStatusStore.setState("isCreateCall", false);
    GlobalStatusStore.setState("isCreateAppeal", true);
    sipControllerStore.resetReinviteTel();
    sipControllerStore.setIsRedirected(false);
  };

  const handleClose = () => {
    store.storageSync?.stopSync();
    localStorage.removeItem(STORAGE_KEY_CREATE_CALL);

    GlobalStatusStore.pauseCallsTemporally();

    onClose(true);
    GlobalStatusStore.setState("isCreateCall", false);
    callStore.getRingTableData("operator");
    sipControllerStore.resetReinviteTel();
    sipControllerStore.setIsRedirected(false);
  };

  return (
    <Modal
      title="Карточка создания звонка"
      isOpen={isOpen}
      onClose={handleClose}
      id={"create-call-modal"}
    >
      <Form
        form={store.form}
        phone={(store.formData.get("clientPhone") as string) || null}
      />
      <FlexRow gap={20}>
        <Button
          onClick={handleCreateAndGoAppeal}
          disabled={store.isLoadings.createCall}
        >
          Сохранить и создать обращение
        </Button>
        <Button onClick={handleCreate} disabled={store.isLoadings.createCall}>
          Сохранить
        </Button>
      </FlexRow>
    </Modal>
  );
};

export default observer(CreateCallModal);
