import "./styles.scss";
import React, { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import TextField from "../../../../../components/TextField";
import Select from "../../../../../components/Select";
import { ICreateCallFormProps } from "./types";
import TextArea from "../../../../../components/TextArea";
import DatePicker from "../../../../../components/DatePicker";

import GlobalStatusStore from "../../../../../stores/GlobalStatusStore";

const Form: FC<ICreateCallFormProps> = ({
  form,
  id,
  phone,
  callId,
  callLineId,
}) => {
  useEffect(() => {
    if (callLineId) {
      form.init(callLineId, "callLineId");
    } else if (id) {
      form.init(id, "callInfoId");
    } else if (callId) {
      form.init(callId, "callId");
    } else if (GlobalStatusStore.callId) {
      form.init(GlobalStatusStore.callId, "callId");
    } else {
      form.init();
    }

    if (id) {
      form.loadCall(id);
    }
    return () => form.reset();
  }, []);

  return (
    <div className="form">
      <Select
        name="topic"
        placeholder="Тема звонка"
        value={form.form.values.topic}
        onChange={(value, name) => form.onChangeTopic(value, name)}
        options={form.options.topic}
        errorMessage={form.form.errors.topic}
      />
      {/** Если есть subTopic но опций нет (все subTopic - deactivated) то не рисовать поле (c) Лера Рева  */}
      {form.subTopic && Boolean(form.subTopic.options.length) && (
        <Select
          name="subTopic"
          placeholder="Подтема звонка"
          value={form.subTopic.form.values.subTopic}
          onChange={(value, name) => form.onChangeSubTopic(value, name)}
          options={form.subTopic.options}
        />
      )}
      <TextField
        name="clientName"
        placeholder="ФИО обратившегося гражданина"
        value={form.form.values.clientName}
        onChange={form.onChangeFormValues}
        errorMessage={form.form.errors.clientName}
      />
      <TextField
        name="patientName"
        placeholder="ФИО пациента"
        value={form.form.values.patientName}
        onChange={form.onChangeFormValues}
        errorMessage={form.form.errors.patientName}
      />
      <Select
        name="socialStatus"
        placeholder="Социальное положение"
        value={form.form.values.socialStatus}
        onChange={form.onChangeFormValues}
        options={form.options.social}
        errorMessage={form.form.errors.socialStatus}
      />
      <Select
        name="medicalOrganization"
        placeholder="Медицинская организация"
        value={form.form.values.medicalOrganization}
        onChange={form.onChangeFormValues}
        options={form.options.medicalOrganisations}
        errorMessage={form.form.errors.medicalOrganization}
      />
      <Select
        name="city"
        placeholder="Населенный пункт"
        value={form.form.values.city}
        onChange={form.onChangeFormValues}
        options={form.options.city}
        errorMessage={form.form.errors.city}
      />
      <TextField
        placeholder="Номер телефона, с которого поступил звонок"
        name="clientPhone"
        value={phone || ""}
        onChange={() => null}
        isDisabled
        maxLength={11}
      />
      <TextArea
        name="description"
        placeholder="Содержание звонка"
        value={form.form.values.description}
        onChange={form.onChangeFormValues}
        errorMessage={form.form.errors.description}
      />
      {/* <Select
        name="shortAnswer"
        placeholder="Краткий ответ"
        value={form.form.values.shortAnswer}
        onChange={form.onChangeFormValues}
        options={form.options.short}
        errorMessage={form.form.errors.shortAnswer}
      /> */}
      <TextField
        name="shortAnswer"
        placeholder="Краткий ответ"
        value={form.form.values.shortAnswer}
        onChange={form.onChangeFormValues}
        errorMessage={form.form.errors.shortAnswer}
      />

      <Select
        name="result"
        placeholder="Результат звонка"
        value={form.form.values.result}
        onChange={form.onChangeFormValues}
        options={form.options.result}
        errorMessage={form.form.errors.result}
      />
      {form.attribute &&
        form.attribute.attributes.map((attribute) => {
          const commonProps = {
            key: attribute.attribute.id,
            name: "attribute",
            value: attribute.form.values.attribute,
            onChange: attribute.changeAttributeValue,
            placeholder: attribute.attribute.title,
            errorMessage: attribute.form.errors.attribute,
          };
          if (attribute.attribute.type === 2) {
            return <Select {...commonProps} options={attribute.options} />;
          } else if (attribute.attribute.type === 1) {
            return <TextField {...commonProps} />;
          } else if (attribute.attribute.type === 3) {
            return <DatePicker {...commonProps} />;
          } else if (attribute.attribute.type === 4) {
            return <TextArea {...commonProps} />;
          }
        })}
      {/* {form.audio_record && (
        <div className="audio-player">
          <ReactAudioPlayer
            src={form.audio_record as string}
            // showSkipControls
            // showFilledProgress
            // showJumpControls
            autoPlay={false}
            autoPlayAfterSrcChange={false}
          />
          <div className="audio-title">
            <span>
              {result} {year}, {time}
            </span>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default observer(Form);
