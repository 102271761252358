import React, { FC, useState } from "react";
import { Button, FlexRow, Modal } from "@kit/kit-aiss";
import { observer } from "mobx-react-lite";
import { ICreateCallModalProps } from "./types";
import "./styles.scss";
import { STORAGE_KEY_CREATE_CALL } from "../../../constants/storage";
import Form from "../../../pages/OperatorCalls/components/popups/Form";
import { NewCallForm } from "./components/NewCallForm";

const CreateEmptyCallModal: FC<ICreateCallModalProps> = ({
  isOpen,
  onClose,
  store,
}) => {
  const [isFirstStep, setIsFirstStep] = useState(true);

  const handleCreate = async () => {
    if (!store.form.validate()) return;

    const isSuccess = await store.onSave();
    if (!isSuccess) {
      alert("Не удалось сохранить звонок.");
      return;
    }

    onClose(true);
  };

  const handleClose = () => {
    localStorage.removeItem(STORAGE_KEY_CREATE_CALL);
    onClose(true);
  };

  const callLineId =
    "callLineId" in store.form.form.values
      ? Number(store.form.form.values.callLineId)
      : undefined;

  return (
    <Modal
      title="Карточка создания звонка"
      isOpen={isOpen}
      onClose={handleClose}
      id={"create-call-modal"}
    >
      {isFirstStep ? (
        <NewCallForm
          onSelected={(res) => {
            store.setCallData(res);
            setIsFirstStep(false);
          }}
        />
      ) : (
        <>
          <Form
            form={store.form}
            callLineId={callLineId}
            phone={store.clientPhone}
          />
          <FlexRow gap={20}>
            <Button
              onClick={handleCreate}
              disabled={store.isLoadings.createCall}
            >
              Сохранить
            </Button>
          </FlexRow>
        </>
      )}
    </Modal>
  );
};

export default observer(CreateEmptyCallModal);
