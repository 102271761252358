import api from "../../api";

const loadSubThemas = (
  themId: string,
  category?: string,
  withDeactivated?: boolean
) => {
  return api.get(
    `/api/topic/${themId}/subtopic${
      category ? `?category=${category}&` : "?"
    }sort[title]=asc&filter[withDeactivated]=${withDeactivated ?? false}`
  );
};

const loadSubTheme = (themId: string, subThemId: string) => {
  return api.get(`/api/topic/${themId}/subtopic/${subThemId}`);
};

const createSubTheme = (id: string, formData: FormData) => {
  return api.post(`/api/topic/${id}/subtopic`, formData);
};

const editSubTheme = (
  topicId: string,
  subtopicId: string,
  formData: FormData
) => {
  return api.post(`/api/topic/${topicId}/subtopic/${subtopicId}`, formData);
};

const deactivateSubTheme = (form: FormData) => {
  return api.post(`/api/topics-disable`, form);
};

const activateSubTheme = (form: FormData) => {
  return api.post(`/api/topics-enable`, form);
};

const subThemasService = {
  loadSubThemas,
  loadSubTheme,
  createSubTheme,
  editSubTheme,
  deactivateSubTheme,
  activateSubTheme,
};

export default subThemasService;
