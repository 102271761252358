import { makeAutoObservable, runInAction } from "mobx";
import { ISubTheme } from "./types";
import subThemasService from "../../../services/subthemas";
import { AttributeBlockStore } from "../../Attribute/components/AttributeBlock/store";

export class SubThemesStore {
  subThemes: ISubTheme[] = [];
  attributeBlockStore: AttributeBlockStore = new AttributeBlockStore();
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  init = (themId: string, type: "theme" | "subTheme", tableType: string) => {
    this.isLoading = true;
    this.loadSubThemas(themId, tableType)
      .then(() => {
        if (this.subThemes.length === 0) {
          runInAction(() => {
            this.attributeBlockStore.loadAttributes().then(() => {
              this.attributeBlockStore.loadTopic(themId, type);
            });
          });
        }
      })
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  loadSubThemas = (themId: string, tableType?: string) => {
    this.isLoading = true;
    return subThemasService
      .loadSubThemas(themId, tableType, true)
      .then(({ data }) => {
        runInAction(() => {
          this.subThemes = data.data.map((them: any) => ({
            id: them.id,
            caption: them.title,
            parentId: them.parent_id,
            created: them.created_at,
            updated: them.updated_at,
            deactivated: them.deactivated_at,
          }));
        });
      })
      .finally(() => runInAction(() => (this.isLoading = false)));
  };
}
